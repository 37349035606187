var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CRow',{staticClass:"mt-3"},[_c('CCol',{staticClass:"d-flex justify-content-end align-items-center",attrs:{"sm":"12"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
        content: _vm.$t('label.WorkShiftPerVesselType'),
        placement: 'top-end'
      }),expression:"{\n        content: $t('label.WorkShiftPerVesselType'),\n        placement: 'top-end'\n      }"}],staticClass:"d-flex align-items-center",attrs:{"color":"add"},on:{"click":_vm.toggleAdd}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t('label.nuevo')))])],1)],1),_c('CCol',{attrs:{"sm":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"hover":"","sorter":"","column-filter":"","table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"items":_vm.formatedItems,"fields":_vm.fields,"loading":_vm.isLoading,"noItemsView":_vm.tableText.noItemsViewText,"items-per-page":5,"active-page":_vm.activePage,"pagination":""},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('loading')]},proxy:true},{key:"RowNumber",fn:function({ item }){return [_c('td',{staticClass:"center-cell"},[_vm._v(" "+_vm._s(item.RowNumber)+" ")])]}},{key:"Status",fn:function({item}){return [_c('td',{staticClass:"center-cell"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")])],1)]}},{key:"Details",fn:function({item}){return [_c('td',{staticClass:"py-2 center-cell"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                content: _vm.$t('label.edit')+_vm.$t('label.WorkShiftPerVesselType'),
                placement: 'top-end'
              }),expression:"{\n                content: $t('label.edit')+$t('label.WorkShiftPerVesselType'),\n                placement: 'top-end'\n              }"}],attrs:{"color":"edit","size":"sm"},on:{"click":function($event){return _vm.toggleEdit(item)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1)],1)]}}])})],1),_c('modal-create-shift-vessel-type',{attrs:{"modal":_vm.modalAdd},on:{"update:modal":function($event){_vm.modalAdd=$event},"submited":_vm.handleSubmit}}),_c('modal-edit-shift-vessel-type',{attrs:{"modal":_vm.modalEdit,"shift-vessel-type-data":_vm.shiftVesselType},on:{"update:modal":function($event){_vm.modalEdit=$event},"submited":_vm.handleSubmit}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }